body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}

.logo-menu-position{
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 100px;
  z-index: 1000;
  filter: brightness(1)
}

.logo-menu-position:hover{
  filter: brightness(1.2);
  width: 120px;
  transition-duration: 500ms;
}

.ReactTable .-pagination .-btn {
  background-color: var(--dark) !important;
  background: var(--dark) !important;
  background-image: var(--dark) !important;
  color: white !important;
  border-radius: 0 !important;
}

.ReactTable .-pagination .-btn:hover,
.ReactTable .-pagination .-btn:focus,
.ReactTable .-pagination .-btn:active,
.ReactTable .-pagination .-btn.active,
.ReactTable .-pagination .-btn:active:focus,
.ReactTable .-pagination .-btn:active:hover,
.ReactTable .-pagination .-btn.active:focus,
.ReactTable .-pagination .-btn.active:hover {
  background-color: var(--dark) !important;
  background: var(--dark) !important;
  background-image: var(--dark) !important;
  filter: brightness(115%);
  color: white;
  border-radius: 0 !important;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  border-radius: 0px !important;
}
